@import url("https://use.typekit.net/lfe5ljt.css");
* {
  box-sizing: border-box;
}

body {
  text-align: center;
  background-color: #eeeeee;
}
h1 {
  font-family: fatfrank, sans-serif;
  font-style: normal;
  font-size:26px !important;
  margin-top: 10px !important;
  text-align: center;

}

/* header flex */
.header-row {
  display : -webkit-flex;
  -webkit-flex-flow: row; 
  max-width: 500px;
  white-space: nowrap; 
}

.header-flex {
  -webkit-flex: 1;
  flex:1;
  margin-left: 10px;
}

.header-flex.one {
  -webkit-flex: 3;
  flex:3;
  -webkit-order: 1;
  order:1;
}
.header-flex.two {
  -webkit-order: 1;
  order:1;

}
/* nav icons */
.header {
  align-items: center !important;
}
.game-icons{
  display: flex;
  align-items: center;
}
.settings-icon {
  margin-left: 10px;
  margin-right: 10px;
}
.right-icons {
  justify-content: flex-end !important;
}
.about-icon {
  margin-right: 10px;
}
.shop-icon {
  color: #212529; 
}
.shop-icon::after {
  /*content: url("/public/new.svg");*/
  color:#198754;
  content: "New";
  font-weight:bold;
  font-size:10px;
  margin: -29px 0 0 -19px;
}
.stats-icon {

}
.custom-word-icon {
  margin: 0px 0px 0px 0px;

}
/*.custom-word-icon::after {
  color:#198754;
  content: "New";
  font-weight:bold;
  font-size:10px;
  margin: -29px 0 0 -19px;
} */
.pointer {
  cursor: pointer;
}

.game-id {
  color:#404040;
}

@media only screen and (max-width: 420px) {
  h1 {
  font-size:26px;
  }
}
@media only screen and (max-width: 320px) {
  h1 {
  font-size:21px !important;
  }
}



.custom-word-input {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  text-transform: lowercase;
}
.custom-word-input:focus {
  color: #212529;
  background-color: #fff;
  border-color: #198754;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(25 135 84 / 55%);;
}

/*confetti*/
.confetti-loc {
  margin-left: 50%;
  margin-right: 50%;
}

/*alerts */
.feedback {
  position: fixed;
  z-index: 1000;
  text-align: center;
  max-width: 320px;
  margin: 5% auto; /* Will not center vertically and won't work in IE6/7. */
  left: 0;
  right: 0;
  top: 92px;
}

.feedback .alert{
  -webkit-animation: alert-outlet-slide 0.2s forwards;
          animation: alert-outlet-slide 0.2s forwards;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  left: -10000px;
  -webkit-transition: margin-top 1.5s ease-in, opacity 300ms linear;
  transition: margin-top 1.5s ease-in, opacity 300ms linear;

}  
/* ALERTS */
.alert-outlet {
  top: 180px;
  left: 0;
  position: fixed;
  z-index: 1000;
  width: 100%;
  text-align: center;
}
.alert-enter {
  opacity: .0;
}

.alert-exit {
  opacity: 1;
}

.alert-enter-active {
  -webkit-animation: alert-outlet-slide 0.2s forwards;
  animation: alert-outlet-slide 0.2s forwards;
-webkit-animation-timing-function: ease;
  animation-timing-function: ease;
left: -10000px;
-webkit-transition: margin-top 1.5s ease-in, opacity 300ms linear;
transition: margin-top 1.5s ease-in, opacity 300ms linear;
opacity: 1;

}

.alert-exit-active {
  margin-top: -10000px;
  -webkit-animation: alert-outlet-slide 0.2s forwards;
          animation: alert-outlet-slide 0.2s forwards;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  left: -10000px;
  -webkit-transition: margin-top 1.5s ease-in, opacity 300ms linear;
  transition: margin-top 1.5s ease-in, opacity  300ms linear;
  scale: 1;
}

.alert-outlet .alert.show {
  margin-top: 0;
}

@-webkit-keyframes alert-outlet-slide {
  100% {
    left: 0;
  }
}

@keyframes alert-outlet-slide {
  100% {
    left: 0;
  }
}


.RowL {
  display: flex;
  justify-content: center;
}

.Row-Left {
  display: flex;
  justify-content: flex-start;
}

.RowL-letter {
  margin: 2px;
  border: 2px solid  rgba(175, 175, 175, 0.69);
  border-radius: 8px;
  width: 58px;
  height: 58px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
}

.hint-letter {
  margin: auto;
  border: 2px solid rgba(0, 0, 0, 0.3);
  background: repeating-linear-gradient(45deg, #e9c601, #e9c601 5px, #e9be01 5px, #e9be01 10px);
  color: white;
  max-width: 58px;
  height: 58px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
}

.hint-hidden {
  display:none;
}
.hint-viewable {
  display: inline;
}

.App-container {
  max-width: 500px;
  margin:auto;
}


.Game {
  display: flex;
  flex-direction: column;
  user-select: none;
  overflow: hidden;
}

.Game-keyboard {
  display: flex;
  flex-direction: column;
  padding: 10px
}

.Game-keyboard-row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}

.Game-keyboard-button {
  margin: 2px;
  background: linear-gradient(#edeeef, #f6f7f8);
  border-bottom: .1em solid #dbdbdc;
  border-top: .1em solid #fff;
  border-radius: 0.25em;
  box-shadow: 0 1px 3px #67696e, inset 0 0 5px #fff;
  padding: 2px;
  text-transform: capitalize;
  border-radius: 4px;
  min-height: 40px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: inherit;
  text-decoration: inherit;
  border: 2px solid transparent;
  cursor: pointer;
  -webkit-appearance: none;
}

.Game-keyboard-button-wide {
  flex: 2;
}

.Game-keyboard-button:focus {
  outline: none;
}

.letter-correct {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background: #8FC941;
  color: white;
  box-shadow:none;

}

.letter-elsewhere {
  border: 2px solid rgba(0, 0, 0, 0.3);
  background: repeating-linear-gradient(45deg, #FFB000, #FFB000 5px, #f0aa13 5px, #f0aa13 10px);
  color: white;
  box-shadow:none;

}

.letter-absent {
  border: 2px solid transparent;
  background: rgb(162, 162, 162) ;
  color: white;
  box-shadow:none;
}


/* high contrast */
.Game.color-blind .letter-correct,
.RowL-letter.letter-correct.color-blind{
  background: #F97316 !important;
}

.Game.color-blind .letter-elsewhere,
.RowL-letter.letter-elsewhere.color-blind {
  background: repeating-linear-gradient(45deg, #06B6D4, #06B6D4 5px, #06c0e0 5px, #06c0e0 10px);
}

.hint-letter.color-blind {
  background: repeating-linear-gradient(45deg, #06B6D4, #06B6D4 5px, #06c0e0 5px, #06c0e0 10px);
}


/* DARK MODE */
@media (prefers-color-scheme: dark) {
  body {
    background-color: #0f0f0f !important;
    color: #e0e0e0;
  }
  h1 {
    color: #cdcdcd;
  }
  .Game-keyboard-button {
    color: #404040;
  }
  .game-icons {
    color:#cdcdcd;
  }
  .shop-icon {
    color: #cdcdcd !important; 
  }
  .RowL-letter {
    border: 2px solid rgba(255, 255, 255, 0.4);
    color: #cdcdcd;
  }
  .letter-correct {
    border: 2px solid rgba(0, 0, 0, 0.3);
    color: white;
  }

  .letter-elsewhere {
    border: 2px solid rgba(0, 0, 0, 0.3);
    color: white;
  }

  .letter-absent {
    border: 2px solid transparent;
    background-color: rgb(142, 142, 142);
    color: white;
  }

  .modal-content {
    background-color: #0f0f0f !important;
    color: #e0e0e0;
  }
  .btn-close {
    filter: invert(.8);
   }

   .alert-danger .btn-close {
     filter:invert(0);
   }
   .alert-danger .btn-close {
    filter:invert(0);
  }
  .alert-success .btn-close {
    filter:invert(0);
  }


  }
/* END DARK MODE */



.Game-options {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 20px auto;
}

.Game-options > * + * {
  margin-inline-start: 0.5rem;
}

.Game-options-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  width: 100%;
}

.Game-options button {
  min-width: 4rem;
}

.Game-stats {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  padding: 20px 0px;
}
.game-stat-item{
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.App-footer {
  margin: -1rem 0 2rem 0;
  font-size: 80%;
  line-height: 1.5;
}

.App-about {
  margin-top: -1rem;
  line-height: 1.4;
  text-align: left;
}

/* Boot Strap Customization and helpers*/ 
/*progress bar */ 
.progress {
  display: grid!important;
  font-size: .75rem !important;
  height:auto;
  border: none !important;
  background-color:transparent !important;
  align-content: space-evenly;

}
.progress-bar {
  overflow: visible !important;
  align-items: center !important;
  height: 1rem;

}
#bar-label div {
  padding: 0 10px 0px 10px;
  height: 1.25rem;
}
.guess-distr-cont {
  display:flex;
  align-items: baseline;
}

.bar-wrap {
  width:100%;
  margin-left: 4px;
  margin-bottom: .25rem;
}

.top-zindex {
  z-index: 99999999999 !important;
}

.small-text{
  font-size:12px;
}
.form-select {
  width: auto !important;
}
.bold {
  font-weight: 600!important;
}
.inline-block {
  display: inline-block !important;
}
.inline-flex {
  display: inline-flex !important;
}
.ml-1 {
  margin-left: .25rem !important;
}
.mr-0 {
  margin-right: 0px !important; 
}
.mr-3 {
  margin-right: 1rem !important; 
}



/* Bottom Fixed */
@media only screen and (max-width: 600px) {
.mobile-bottom {
  bottom: 0%;
  padding: 0px;
  width: 300px;
  height: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  position: fixed;
  }
}
@media only screen and (max-height: 605px)  {
  .mobile-bottom {
    display:none;
  }
}
@media only screen and (min-width: 601px)  {
  .mobile-bottom {
    display:none;
  }
}
@media only screen and (min-width: 800px) {
  .large-bottom {
    bottom: 0%;
    padding: 0px;
    width: 800px;
    max-height: 285px;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
}
}

@media only screen and (max-width: 800px)  {
  .large-bottom {
    display:none;
  }
}

@media only screen and (max-height: 680px)  {
  .large-bottom {
    display:none;
  }
}
